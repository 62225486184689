import React, {useEffect} from 'react';
import {Font} from "./Font";
import {Typography, useMediaQuery, useTheme} from "@material-ui/core";

const MARKET_LINK = 'https://apps.apple.com/app/id1575732157';

const Header = () => (
    <div>
        <img src={'/header.png'} style={{
            width: '80px',
            height: '80px',
            borderRadius: '19px',
            border: '1px solid rgba(255, 255, 255, 0.3)'
        }}/>
    </div>
);

const Title = ({isMobileView}: { isMobileView?: boolean }) => (
    <Typography style={isMobileView ? {
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '28px',
        lineHeight: '38.25px',
        color: '#FFFFFF',
        textAlign: 'center'
    } : {
        fontFamily: 'Avenir Next',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '56px',
        lineHeight: '76px',
        color: '#FFFFFF',
        zIndex: 2
    }}>Instagram
        Account Analyzer</Typography>
)

const SubTitle = ({isMobileView}: { isMobileView?: boolean }) => (
    <Typography style={isMobileView ?
        {
            fontFamily: 'Avenir Next',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '16px',
            lineHeight: '22px',
            color: '#E1E1E1',
            textAlign: 'center'
        } : {
            fontFamily: 'Avenir Next',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '22px',
            lineHeight: '36px',
            color: '#FFFFFF',
            zIndex: 2
        }}>Check who has unfollowed you, who is interested in your profile, who visits your page,
        how interesting your friends profiles are, and more!</Typography>
)

const DownloadButton = ({isMobileView}: any) => (
    <img src={'/BUTTON.png'} style={{
        cursor: 'pointer',
        width: !isMobileView ? '220px' : '176px',
        height: !isMobileView ? '70px' : '56px',
        zIndex: 2
    }} alt={"Download button"} onClick={() => window.location.href = MARKET_LINK}/>
)

const CustomLink = ({children, onClick}: any) => (
    <Typography style={{marginRight: '30px', textDecoration: 'underline', cursor: 'pointer'}} onClick={onClick}>
        {children}
    </Typography>
)

export type dialogType = 'none' | 'terms' | 'privacy' | 'billing';

const Footer = ({flexDirection, isMobileView}: any) => {
    const handleClick = (dialogType: dialogType) => {
        switch (dialogType){
            case "billing":window.open('/billing.html', "_blank"); break
            case "terms": window.open('/terms.html', "_blank"); break
            case "privacy": window.open('/privacy.html', "_blank"); break
        }

    }
    return (
        <>
            <div style={{
                display: 'flex',
                flexDirection: flexDirection,
                alignItems: isMobileView ? 'center' : 'flex-end',
                justifyContent: isMobileView ? 'space-around' : 'flex-start',
                width: '100%',
                height: isMobileView ? '100%' : '30%',
                color: '#FFFFFF',
                opacity: 0.6,
                fontFamily: 'Avenir Next',
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '14px',
                lineHeight: '19px',
                textAlign: isMobileView ? 'center' : 'start',
                zIndex: 2,
               // marginBottom: isMobileView ? '40px' : '0px',
            }}>
                <Typography style={{
                    marginRight: '30px',
                    borderRight: !isMobileView ? '1px solid #353545' : 'none'
                }}>
                    Copyright ® 2021 Vibrainapps. All Rights Reserved
                </Typography>
                <CustomLink onClick={() => handleClick('terms')}>
                    Terms and Conditions
                </CustomLink>
                <CustomLink onClick={() => handleClick('privacy')}>
                    Privacy Policy
                </CustomLink>
                {/*<CustomLink onClick={() => handleClick('billing')}>*/}
                {/*    Billing Terms*/}
                {/*</CustomLink>*/}
                <Typography style={{marginRight: '30px', textDecoration: 'underline', cursor: 'pointer'}}
                            onClick={() => window.location.href = 'mailto:info@vibrain.pro'}>
                    support@vibrainapps.com
                </Typography>
            </div>
        </>
    )
}

const DesktopView = () => (
    <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: "flex-start",
        justifyContent: 'center',
        height: '100%',
        width: '100%',
        padding: '40px 0px'
    }}>
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
            width: '50%',
            height: '100%'
        }}>
            <Header/>
            <Title/>
            <SubTitle/>
            <DownloadButton/>
        </div>
        <Footer flexDirection={'row'}/>
    </div>
)

const MobileView = ({isMobileView}: { isMobileView: boolean }) => (<>
        <div style={{display: 'flex', flex: 1}}/>
        <div style={{
            display: 'flex',
            flex: 2,
            flexDirection: 'column',
            alignItems: "center",
            justifyContent: 'space-between',
            height: 'calc((var(--vh, 1vh) * 100))',
            width: '100%'
        }}>
            <Header/>
            <Title isMobileView={true}/>
            <SubTitle isMobileView={true}/>
            <DownloadButton isMobileView={true}/>
        </div>
        <div style={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            width: '100%',
            borderTop: '1px solid #353545',
            marginTop: '20pt'
        }}><Footer flexDirection={'column'} isMobileView={isMobileView}/></div>

    </>
)

export default function App() {
    const theme = useTheme();
    const isMobileView = useMediaQuery(theme.breakpoints.down('md'));
    useEffect(() => {
        // used for IPhone
        function updateVhProperty() {
            debugger
            requestAnimationFrame(() => {
                requestAnimationFrame(() => {
                    const vh = window.innerHeight * 0.01;
                    document.documentElement.style.setProperty('--vh', `${vh}px`);
                });
            });
        }

        updateVhProperty();
        window.addEventListener('orientationchange', updateVhProperty);
        if (isMobileView) {
            window.removeEventListener('resize', updateVhProperty);
        } else {
            window.addEventListener('resize', updateVhProperty);
        }
        return () => {
            window.removeEventListener('orientationchange', updateVhProperty);
            window.removeEventListener('resize', updateVhProperty);
        };
    }, [isMobileView]);
    return (
        <Font isMobileView={isMobileView}>
            {isMobileView ? <MobileView isMobileView={isMobileView}/> : <DesktopView/>}
        </Font>
    );
}
