import React, {ReactElement} from "react";

export const Font = ({children, isMobileView}: { children: ReactElement, isMobileView: boolean }) => (
    <>{isMobileView ?
        <div style={{
            backgroundImage: `url(/Bgd_Image_mob.png)`,
            height: '100vh',
            display: "flex",
            flexDirection: 'column',
            alignItems: 'center',
            width: '100vw',
            justifyContent: 'center',
            overflow: 'hidden',
            padding: '20px',
            backgroundSize: 'cover'
        }}>
            {children}
        </div> : <div style={{
            backgroundImage: `url(/font.png)`,
            height: '100vh',
            display: "flex",
            overflow: 'hidden',
            paddingLeft: '10vw',
            backgroundSize: 'cover'
        }}>
            <div style={{
                backgroundImage: `url(/Bgd_image.png)`,
                backgroundSize: 'cover',
                position: 'absolute', height: '100%', width: '100%',
                overflow: 'hidden',
                paddingLeft: '10vw',
                top: 0, right: 0
            }}>
                {children}
            </div>
        </div>
    }</>
)