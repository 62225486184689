import red from '@material-ui/core/colors/red';
import {createMuiTheme} from '@material-ui/core/styles';

// A custom theme for this app
const theme = createMuiTheme({
  breakpoints: {
    values: {
      /** iPhone 5/SE */
      xs: 320,
      /** iPhone 6/7/8/X */
      sm: 375,
      /** iPhone 6/7/8 Plus */
      md: 414,
      /** iPad 1, 2, Mini and Air */
      lg: 768,
      xl: 1024,
    }
  },
  typography: {
    h1: {

    },
    h2: {

    },
  },
  palette: {
    primary: {
      main: '#556cd6',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#fff',
    },
  },
});

export default theme;
